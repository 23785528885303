.Input {
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Input[type='number']::-webkit-inner-spin-button,
.Input[type='number']::-webkit-outer-spin-button,
.Input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
