.thumb {
  @apply bg-grey-minus1 relative block h-10 w-26 rounded-full cursor-pointer;
  @apply transition-all duration-300 ease-in-out;
}

.thumb:after {
  @apply bg-white absolute block w-16 h-16 rounded-full shadow-thumb;
  @apply transition-all duration-300 ease-in-out;
  left: -2px;
  top: -3px;
  content: '';
  transform: 'translate(-8px, -6px)';
}

.thumb:active:after {
  transform: 'translate(22px, -6px)';
}

.track {
  @apply sr-only;
}

.track:checked ~ span {
  @apply bg-primary-minus1;
}

.track:checked ~ span:after {
  @apply bg-primary-plus1;
  left: 10px;
}

.track:disabled ~ span {
  @apply bg-grey-minus2 pointer-events-none;
}

.track:disabled ~ span:after {
  @apply bg-grey-minus1;
}
