.sidebar-toggle-all,
.child-indicator--small {
  /*
   * apply ((16px big circle width - 10px small circle width) / 2) left margin
   * so both circles align horizontally
   */
  margin-left: calc((16px - 10px) / 2);
  min-width: 10px;
}

.sidebar-item--bundle::before,
.sidebar-item--topic::before {
  content: '';
  @apply absolute h-56 border border-blue-grey;
  /*
   * position the line in the middle 2px of the 16px space that the circle
   * occupies. That's 22px space from the left edge + 7px ((16px circle width / 2) - 1).
   */
  left: calc(22px + 7px);
}

.sidebar-item--bundle.last::before,
.sidebar-item--topic.last::before {
  /*
   * display only top-half vertical line when the child is the last child
   */
  height: calc(56px / 2);
  top: 0;
}

.sidebar-item--bundle + .sidebar-item--bundle {
  @apply border-t-0;
}

.child-indicator {
  transition: transform 0.1s ease;
}

.hidden {
  display: none;
}
