.ValueContainer {
  @apply bg-white;
  @apply py-12;
  @apply px-16;
  @apply border;
  @apply border-grey-minus1;

  border-radius: 4px;
}

.ValueContainer.show-points-enabled > .ContentPreview {
  display: flex;
  justify-content: space-between;
}
