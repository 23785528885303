/* 
  Fix for clicking of SVG in TopicContentEditorSidebar
  ref: https://github.com/quipper/quipper/issues/30677
*/
#topic-content-editor-sidebar svg {
  pointer-events: none;
}

.Sidebar {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* Height of the viewport minus total height of navbar */
  height: calc(100vh - 74px);
}

.Sidebar-exit {
  left: 0px;
}

.Sidebar-enter,
.Sidebar-exit-active,
.Sidebar-exit-done {
  left: calc(var(--sidebar-close-width) - var(--sidebar-open-width));
}

.Sidebar-enter-active,
.Sidebar-enter-done {
  left: 0px;
}

.Sidebar-enter-active,
.Sidebar-exit-active {
  transition: left 0.2s;
}

.Sidebar header {
  /* Set box sizing to content-box and height to 54px so the 1px bottom
  border is added to the height (55px) which matches the height of
  the form title */
  @apply box-content border-b border-grey-minus1;
  min-height: 54px;
}
