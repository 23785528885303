.MultilineTruncate {
  text-overflow: ellipsis;

  /* necessary inclusion of prefix as all supported browsers use the -webkit
    prefix, autoprefixer does not add a prefix to these css attributes
    https://github.com/quipper/monorepo/pull/14599#discussion_r371587412 */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clamp-5 {
  -webkit-line-clamp: 5;
}

.clamp-1 {
  -webkit-line-clamp: 1;
}