.ContentWithSidebarContainer {
  --sidebar-close-width: theme(spacing.40);
  --sidebar-open-width: theme(spacing.280);
}

/* Push content depending on the state of the sidebar */
.ContentWithSidebarContainer > nav + * {
  /* 
   * ".ContentWithSidebarContainer > * + *" selector was used before but this
   * breaks the sidebar when Grammarly Chrome extension is used because it
   * inserts another element as the first child (<grammarly-extension />)
   * inside .ContentWithSidebarContainer. The previous selector would apply the
   * width rule below to the sidebar instead of the main content. To make sure
   * that the style is applied to the sidebar we make the selector more
   * specific by using nav instead of *.
   *
   * See https://github.com/quipper/quipper/issues/25985
   */
  transition: width 0.2s, margin 0.2s;
  width: calc(100% - var(--sidebar-close-width));
  margin-left: var(--sidebar-close-width);
}

.ContentWithSidebarContainer .isOpen + * {
  width: calc(100% - var(--sidebar-open-width));
  margin-left: var(--sidebar-open-width);
}
