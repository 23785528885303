.rc-tooltip-inner {
  @apply border-0;
  @apply bg-grey-plus3;
  @apply text-white;
  @apply max-w-600;
}

.rc-tooltip-arrow {
  border-bottom-color: #0c2846 !important;
  top: -4px !important;
}

.MatrixTooltip.rc-tooltip {
    @apply p-0;
    @apply rounded;
  opacity: 1;
  box-shadow: 0 1px 3px 0 rgba(30, 63, 107, 0.20);
  .rc-tooltip-inner {
    @apply bg-white;
  }
}

.MatrixTooltipHide {
  display: none;
}