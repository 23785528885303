@tailwind base;

:root {
  @apply font-body;
  @apply text-grey-plus2;
  @apply text-14;
  @apply min-w-1200;
  @apply min-h-screen;
}

body {
  /* To make absolute positioned elements (e.g. Modal overlay) behave correctly */
  @apply relative;
  /* Apply word-break by default to handle layout issues from user-generated text (URLs, titles, etc.) */
  @apply break-words;
}

@tailwind components;

/* z-index settings */

.Navbar {
  @apply z-10;
}

/*
 * higher than Navbar because there are dropdown menus (e.g. org switcher and
 * avatar menu) that are children of Navbar and they should not be shown behind
 * the nav bar
 */
.DropdownMenu {
  @apply z-20;
  overflow-y: scroll !important;
}

/*
 * higher than everything else because the modal and its backdrop should be on
 * top
 */
.bg-modalOverlay {
  @apply z-30;
}

/* z-index settings end */

@import './components/atoms/ValueContainer.css';

.Pill {
  @apply bg-grey-minus1;
  @apply rounded-full;
  @apply py-2;
  @apply px-8;
  @apply text-12;
  @apply text-center;
  @apply font-bold;
}

.Icon {
  @apply border;
  @apply border-grey-minus1;
  @apply rounded-full;
}

.RowItem {
  @apply h-64;
  @apply bg-white;
  @apply border-r-0;
  @apply border-b;
  @apply border-l-0;
  @apply border-grey-minus2;
  @apply cursor-pointer;
}

/*
 * Creates an illusion that this row is stacked on top of other rows so it
 * looks like a stack of rows. This is used for when reordering linked
 * questions where a group of linked questions are reordered as a group.
 */
.RowItem.Group {
  /* height of row (64px) + height of stacked rows background image (6px) */
  @apply h-70;

  background-image: url('assets/stack-border.png');
  @apply bg-bottom bg-repeat-x;
}

.RowItem:hover {
  @apply bg-primary-minus2;
}

.RowItemDisabled,
.RowItemDisabled:hover {
  @apply bg-grey-minus3 text-grey cursor-not-allowed;
}

.RowItem:last-child {
  @apply border-b-0;
}

@tailwind utilities;

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

/* Vertically center an absolutely positioned element */
.inset-y-center {
  top: 50%;
  transform: translateY(-50%);
}

/* Horizontally center an absolutely positioned element */
.inset-x-center {
  transform: translateX(50%);
}

/* Apply word-break: break-word to very long word */
.break-long-word {
  word-break: break-word;
}
