.LoadingText {
  animation-name: pulse;
  animation-timing-function: ease-in;
  animation-duration: 1.2s;
  animation-delay: 0;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
