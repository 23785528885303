.Banner {
  .Content {
    height: 54px;
  }
  transition: 0.2s;
  /* Hidden initial state */
  opacity: 0;
  transform: translateY(-10px);

  &.entered {
    /* Animate enter state */
    opacity: 1;
    transform: translateY(0px);
  }

  &.exited {
    /* Animate exit state */
    opacity: 0;
    transform: translateY(-10px);
  }
}
